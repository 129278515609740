// @codekit-prepend "../bower_components/jquery-backstretch/jquery.backstretch.min.js";


$(document).ready(function() {

  $.backstretch([
    "../img/foto-01.jpg",
    "../img/foto-02.jpg",
    "../img/foto-03.jpg"
  ], {duration: 3000, fade: 750});

});



var menuButton = document.getElementById('menuButton');
menuButton.addEventListener('click', function (e) {
    menuButton.classList.toggle('is-active');
    $(".menu").fadeToggle();
    e.preventDefault();


});

$("div.menu ul li a").click(function(e) {
  e.preventDefault();
  $(".menu").fadeOut();
  $("#menuButton").removeClass("is-active");

  $("div.content").fadeOut();

  $("div"+$(this).attr("href")).delay(500).fadeIn();

})
